import constants from "./constants";

const MESSAGE_STORAGE = {
    topic1: [{ sender: "system", content: "Message 1 for Topic 1" }, { sender: "system", content: "Message 2 for Topic 1" }],
    topic2: [{ sender: "system", content: "Message 1 for Topic 2" }],
};

export const addMessageToTopic = (topicKey, message) => {
    if (!MESSAGE_STORAGE[topicKey]) {
        MESSAGE_STORAGE[topicKey] = []; // Initialize the topic if it doesn't exist
    }
    MESSAGE_STORAGE[topicKey].push(message); // Add the new message
};

export const getMessagesByTopic = (topicKey) => MESSAGE_STORAGE[topicKey] || [];

function empty(array) {
    return array.length === 0;
}

const fetchNodeChat = async (lastUserMessage, lastBotMessage, topicKey, topic) => {
    if (!topicKey) {
        throw new Error("Topic key is required to fetch messages.");
    }

    var messages = getMessagesByTopic(topicKey)
    if (empty(messages)) {
        MESSAGE_STORAGE[topicKey] = [lastUserMessage]
    } else {
        // if (lastBotMessage) {
        //     messages.push(lastBotMessage)
        // }

        if (lastUserMessage) {
            messages.push(lastUserMessage)
        }

        MESSAGE_STORAGE[topicKey] = messages
    }

    messages = getMessagesByTopic(topicKey)

    const response = await fetch(
        `${constants.fetchNodeChat}`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                topic_: topic,
                topic: topicKey,
                messages: messages
            }),
            credentials: "include"
        }
    );

    const message = await response.json();

    const botMessage = {
        role: 'assistant',
        content: message.message || message,
        timestamp: new Date(),
        status: response.status
    };

    messages.push(botMessage)
    MESSAGE_STORAGE[topicKey] = messages

    return {
        message: botMessage,
        reinforcementLabel: message.reinforcementLabel || 0
    }
}

export default fetchNodeChat;
