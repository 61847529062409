import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Graph from 'react-vis-network-graph';
import constants from '../helpers/constants';
import Navbar from './Navbar';
import { useParams } from 'react-router-dom';
import { useNotification } from 'rc-notification';
import NotificationContainer from './NotificationContainer';
import Modal from './Modal';
import TaskQueue from '../components/TaskQueue';
import { useCloseModalListener } from '../hooks/useCloseModalListener';
import { useScreenResizing } from '../hooks/useScreenResizing';
import { useInitialNodeSetup } from '../hooks/useInitialNodeSetup';
import { useHandleGraphUpdates } from '../hooks/useHandleGraphUpdates';
import useHandleNewGameState from '../hooks/useHandleNewGameState';
import useNodeSelection from '../hooks/useNodeSelection';
import Chat from './Chat';
import LoginModal from './LoginModal';
import { deleteTopic } from '../helpers/indexedDb';
import { motion } from '../helpers/motion';
import deleteTopicFromServer from '../helpers/deleteGraphFromServer';
import { Menu, Item, useContextMenu } from "react-contexify";
import "../style/Graph.css";
import "../style/ReactContexify.css";
import addNodeAndUpdateGraph from '../helpers/addNodeAndUpdateGraph';
import NodeEntity from '../entities/NodeEntity';
import { getNodeContent } from '../helpers/getNodeContent';
import removeNodeAndUpdateGraph from '../helpers/removeNodeAndUpdateGraph';
import removeEdgeAndUpdateGraph from '../helpers/removeEdgeAndUpdateGraph';
import NodeContent from './NodeContent';
import findParents from '../helpers/findParents';
import EdgeEntity from '../entities/EdgeEntity';
import addEdgeAndUpdateGraph from '../helpers/addEdgeAndUpdateGraph';
import ResponsiveCardSwiper from './ResponsiveCardSwiper';
import postUseCard from '../helpers/postUseCard';
import notifyChallengeInvite from '../helpers/notifyChallengeInvite';
import useAnimatedPoints from '../hooks/useAnimatedPoints';
import SparkleCanvas from './SparkleCanvas';
import getNeighborsGraphById from '../helpers/getNeighborsGraphById';
import { IoCloseCircle } from 'react-icons/io5';
import { notifyGetAnswer } from './notifyGetAnswer';

const taskQueue = new TaskQueue();
taskQueue.start();

const GameGraph = () => {
  const timerRef = useRef();
  const graphRef = useRef();
  const networkRef = useRef();
  const timeoutRefForGraphUpdate = useRef();
  const handleNodeTimerRef = useRef();
  const pulseNodeTimerRef = useRef();
  const answerNotificationTimerRef = useRef();
  const useHandleGraphUpdatesTimerRef = useRef();
  const notifyChallengeInviteRef = useRef();
  const swiperRef = useRef();

  const navigate = useNavigate();
  const { topic } = useParams();
  const [currentTopic] = useState(topic);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [physicsEnabled, setPhysicsEnabled] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [gameState, setGameState] = useState({});
  const [newGameState, setNewGameState] = useState({});
  const [hover, setHover] = useState(null);
  const [edgeHover, setEdgeHover] = useState(false);
  const [lastHoveredNode, setLastHoveredNode] = useState(false);
  const [edgeSelected, setEdgeSelected] = useState(null);

  const [lastSelectedNode, setLastSelectedNode] = useState({});
  const [lastSelectedNodeParents, setLastSelectedNodeParents] = useState([]);
  const [showChat, setShowNodeContent] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [alreadyLoadedNode, setAlreadyLoadedNode] = useState([]);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showChatAboutContextButton, setShowChatAboutContextButton] = useState(false);
  const [addingNewEdgeFrom, setAddingNewEdgeFrom] = useState(false);
  const [addingNewEdge, setAddingNewEdge] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(0);
  const [startHandCards, setStartHandCards] = useState(false);
  const [handCards, setHandCards] = useState([]);
  const [refreshCardGame, setRefreshCardGame] = useState(false);
  const [levelUpCardGame, setLevelUpCardGame] = useState(false);
  const [pointsCardGame, setPointsCardGame] = useState(0);
  const [levelCardGame, setLevelCardGame] = useState(0);
  const [multiplierCardGame, setMultiplierCardGame] = useState(0);
  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const [previousScale, setPreviousScale] = useState(1);
  const displayPoints = useAnimatedPoints(pointsCardGame)

  const [notice, contextHolder] = useNotification({
    showProgress: true,
    getContainer: () => document.getElementById('notification-container'),
    motion,
  });

  const [graph, setGraph] = useState({
    nodes: [
      {
        id: 1,
        label: currentTopic,
        title: currentTopic,
        group: 'main'
      }
    ],
    edges: []
  });

  const addAlreadyLoadedNode = (nodeId) => {
    setAlreadyLoadedNode((prev) => [...prev, nodeId]);
  };

  const nodeAlreadyLoaded = (nodeId) => alreadyLoadedNode.includes(nodeId);

  useHandleNewGameState({
    newGameState,
    setNewGameState,
    setGameState,
    networkRef,
    notice,
    pulseNodeTimerRef,
    answerNotificationTimerRef,
    taskQueue,
    topic
  });

  const togglePhysics = () => {
    setPhysicsEnabled(!physicsEnabled);
  };

  useEffect(() => {
    if (networkRef.current) {
      networkRef.current.setOptions({
        physics: { enabled: physicsEnabled },
      });
    }
  }, [physicsEnabled]);

  useEffect(()=>{
    if (startHandCards){
      scheduleCardAnswerNotification(answerNotificationTimerRef, selectedCardId, notice, networkRef, pulseNodeTimerRef);
    }
  }, [startHandCards])

  useEffect(() => {
    if (notifyChallengeInviteRef.current) { return }

    notifyChallengeInviteRef.current = true

    notifyChallengeInvite(notice, () => { setStartHandCards(true) })
  }, [])

  const handleNodeSelection = useNodeSelection({
    isFetching,
    setIsFetching,
    graph,
    setGraph,
    alreadyLoadedNode,
    addAlreadyLoadedNode,
    nodeAlreadyLoaded,
    networkRef,
    topic,
    setNewGameState,
    setLastSelectedNode,
    setLastSelectedNodeParents,
    setShowNodeContent,
    handleNodeTimerRef,
    timeoutRefForGraphUpdate,
    setPhysicsEnabled,
    taskQueue,
    addingNewEdge,
    setAddingNewEdge,
    addingNewEdgeFrom,
    notice,
    setShowLoginModal
  });

  const handleRemoveTopic = async () => {
    if (!topic) return
    try {
      await deleteTopic(topic)
      await deleteTopicFromServer(topic)
      navigate("/");
    } catch (error) {
      console.error("Error removing topic:", error)
    }
  }

  useHandleGraphUpdates(graph, networkRef, useHandleGraphUpdatesTimerRef);
  useInitialNodeSetup(networkRef, timerRef, handleNodeSelection, setGraph, topic);
  useScreenResizing(setScreenWidth, setScreenHeight);
  useCloseModalListener([setShowLoginModal, setShowNodeContent, setShowEdit]);

  const events = {
    selectNode: (event) => {
      setEdgeSelected(null);

      const { nodes } = event;
      const selectedNode = getNodeContent(graph, nodes[0]);
      setLastSelectedNode(selectedNode);

      if (addingNewEdge) {
        if (selectedNode && selectedNode.id !== addingNewEdgeFrom) {
          const edge = new EdgeEntity(addingNewEdgeFrom, selectedNode.id)
          addEdgeAndUpdateGraph(edge, graph, topic, setGraph)
        }

        setAddingNewEdge(false)
        return;
      }
    },
    hoverNode: (event) => {
      if (showContextMenu === false) {
        setLastHoveredNode(event.node)
      }

      setHover(true)
      document.body.style.cursor = 'pointer';
    },
    blurNode: () => {
      if (!addingNewEdge) {
        document.body.style.cursor = 'default';
      }

      setHover(false)
    },
    selectEdge: (event) => {
      setEdgeSelected(event.edges[0])
    },
    hoverEdge: () => {
      setEdgeHover(true)
    },
    blurEdge: () => {
      setEdgeHover(false)
    },
  };

  const menuId = "canvas-rightclick"

  const { show, hideAll } = useContextMenu({
    id: menuId,
  });

  const handleDoubleClick = (event) => {
    if (networkRef.current) {
      const network = networkRef.current;
      const currentScale = network.getScale();
      if (!isZoomedIn) {
        setPreviousScale(currentScale);
        network.moveTo({
          scale: 0.75,
          animation: { duration: 500, easingFunction: "easeInOutQuad" },
        });
        setIsZoomedIn(true);
      } else {
        network.moveTo({
          scale: previousScale,
          animation: { duration: 500, easingFunction: "easeInOutQuad" },
        });
        setIsZoomedIn(false);
      }
    }
  }

  const handleContextMenu = (e) => {
    e.preventDefault()

    if (addingNewEdge) {
      return;
    }

    if (hover || edgeHover) {
      show({
        event: e,
      })

      setShowContextMenu(true);
      setShowChatAboutContextButton(hover)
    }
  };

  const handleAddNewNode = () => {
    const newNode = new NodeEntity({
      id: graph.nodes.at(-1).id + 1,
      label: "New node",
      title: "New node"
    });
    addNodeAndUpdateGraph(newNode, networkRef, graph, topic, setGraph);
  }

  useEffect(() => {
    const hasGraphChildren = document.querySelector('body .graph') !== null;

    if (hasGraphChildren) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className='graph'>
      <div className='absolute z-1 center-y center-x'>
        <div className={`points-big ${displayPoints !== pointsCardGame ? 'popIn' : 'popOut'}`}>
          <p>Total Points: +{displayPoints}</p>
        </div>
        <div className={`level-up-big ${levelUpCardGame ? 'popIn' : 'popOut'}`}>
          <p>Level Up</p>
        </div>
      </div>
      {levelUpCardGame ?
        <div className='absolute'>
          <SparkleCanvas />
        </div> : null}

      <Navbar
        handlePlayClick={togglePhysics}
        playing={physicsEnabled}
        isFetching={isFetching}
        points={pointsCardGame}
        setShowLoginModal={setShowLoginModal}
        handleRemoveTopic={handleRemoveTopic}
        levelCardGame={levelCardGame}
        handleAddNewNode={handleAddNewNode}
      />

      <NotificationContainer>
        {contextHolder}
      </NotificationContainer>

      <div
        ref={graphRef}
        onClick={handleContextMenu}
        onDoubleClick={handleDoubleClick}
        style={{ width: "100%", height: "100%" }}
      >
        <Graph
          getNetwork={(network) => { networkRef.current = network; }}
          options={{ ...constants.graph.options, ...{ height: `${screenHeight}px`, width: `${screenWidth}px` } }}
          events={events}
        />
      </div>

      {
        startHandCards && graph.nodes.length > 2 ?
          <ResponsiveCardSwiper
            graph={graph}
            topics={graph.nodes.map((node) => node.label)}
            selectedCardId={selectedCardId}
            setSelectedCardId={setSelectedCardId}
            handCards={handCards}
            setHandCards={setHandCards}
            noticeService={notice}
            refresh={refreshCardGame}
            setRefresh={setRefreshCardGame}
            setLevelCardGame={setLevelCardGame}
            swiperRef={swiperRef}
            multiplier={multiplierCardGame}
            setShowLoginModal={setShowLoginModal}
            networkRef={networkRef}
            pulseNodeTimerRef={pulseNodeTimerRef}
          />
          :
          null
      }


      {showChat && (
        <Modal title={`${lastSelectedNode.label}`}>
          <Chat
            topic={graph.topic}
            subject={`${lastSelectedNode.label}`}
            notice={notice}
            setShowLoginModal={setShowLoginModal}
            setLevelUpCardGame={setLevelUpCardGame}
          />
        </Modal>
      )}


      {showEdit && (
        <Modal>
          <NodeContent
            node={lastSelectedNode}
            parentNode={lastSelectedNodeParents}
            graph={graph}
            setGraph={setGraph}
            notice={notice}
            setShowLoginModal={setShowLoginModal}
            setShowNodeContent={setShowEdit}
            setShowChat={setShowNodeContent}
          />
        </Modal>
      )}

      {showLoginModal && (
        <LoginModal notification={notice} topic={topic} />
      )}

      <Menu id={menuId}>
        {handCards.length && !edgeSelected ?
          <Item onClick={() => {

            scheduleCardAnswerNotification(answerNotificationTimerRef, selectedCardId, notice, networkRef, pulseNodeTimerRef);
            if (pulseNodeTimerRef.current) {
              pulseNodeTimerRef.current.stopAnimation();
            }

            setHandCards((prevCards) =>
              prevCards.map((card) =>
                card.id === selectedCardId ? {
                  ...card, ...{
                    status: "used"
                  }
                } : card
              )
            );

            const postUseCard_ = async () => {
              const response = await postUseCard({
                cardId: selectedCardId,
                topic: lastSelectedNode.label
              })

              if (response.status === 500) {
                notice.open({
                  content: (
                    <div>
                      <div className="d-flex justify-content-between px-2 mb-2">
                        <small className="tag">Ops!</small>
                        <strong>Something goes wrong</strong>
                      </div>
                      <div>Please try again.</div>
                    </div>
                  ),
                  duration: 30,
                  closable: true,
                  showProgress: false,
                  closeIcon: <IoCloseCircle />,
                });
                setHandCards([])
                setStartHandCards(false)
                notifyChallengeInvite(notice, () => { setStartHandCards(true) })
              }

              setLevelCardGame(response.level)

              const status = response.correctAnswer ? "correct" : "wrong"
              setHandCards((prevCards) =>
                prevCards.map((card) =>
                  card.id === selectedCardId ? {
                    ...card, ...{
                      status
                    }
                  } : card
                )
              );

              setMultiplierCardGame(response.multiplier)

              if (response.correctAnswer) {
                setPointsCardGame(response.points)

                setTimeout(() => {
                  if (swiperRef.current) {
                    swiperRef.current.slideNext()
                  }
                }, 500)

                setTimeout(() => {
                  setHandCards(removeCardAndUpdateIDs(handCards, selectedCardId))
                  swiperRef.current.activeIndex -= 1
                  swiperRef.current.update()
                  setSelectedCardId(swiperRef.current.activeIndex)
                }, 1000);

                notice.close("notifyGetAnswer");
              } else {
                setTimeout(() => {
                  setHandCards((prevHints) =>
                    prevHints.map((hint) =>
                      hint.id === selectedCardId ? {
                        ...hint, ...{
                          status: null
                        }
                      } : hint
                    )
                  );
                }, 500)
              }

              if (response.levelUp) {

                if (response.level === 2 && graph.nodes.length < 20) {
                  const neighbors = getNeighborsGraphById(1, graph)
                  const randomNode = neighbors.nodes[Math.floor(Math.random() * neighbors.nodes.length)]
                  const event = { nodes: [randomNode.id], skipFetch: false, initialNodeSteup: false }
                  handleNodeSelection(event)
                }

                setLevelUpCardGame(true)
                setTimeout(() => {
                  setLevelUpCardGame(false)
                }, 2000)
              }

              setTimeout(() => {
                if (response.needNewHand) {
                  setRefreshCardGame(true)
                }
              }, 1000)
            }

            postUseCard_()
            setShowContextMenu(false);
            hideAll()
          }}>Use card</Item>
          : null}

        {showChatAboutContextButton ?
          <Item onClick={() => {
            const selectedNodeParents = findParents(graph, lastSelectedNode.id);
            if (selectedNodeParents) {
              setLastSelectedNodeParents(selectedNodeParents[0]);
            }

            setShowEdit(true)
            setShowContextMenu(false);
            hideAll()
          }}>Open</Item>
          : null}

        {showChatAboutContextButton ?
          <Item onClick={() => {
            setShowNodeContent(true)
            setShowContextMenu(false);
            hideAll()
          }}>Chat about</Item>
          : null}

        {showChatAboutContextButton ?
          <Item onClick={() => {
            const event = { nodes: [lastSelectedNode.id], skipFetch: false, initialNodeSteup: false }
            handleNodeSelection(event)
            setShowContextMenu(false);
            hideAll()
          }}>Expand</Item>
          : null}

        {showChatAboutContextButton && addingNewEdge === false ?
          <Item onClick={() => {
            setAddingNewEdge(true)
            setAddingNewEdgeFrom(lastSelectedNode.id)
            document.body.style.cursor = 'pointer'
            setShowContextMenu(false);
            hideAll()
          }}>Connect with</Item>
          : null}

        {showChatAboutContextButton ?
          <Item onClick={() => {
            window.location.href = `/${lastSelectedNode.label}`;
          }}>Start a new graph</Item>
          : null}

        {edgeSelected ?
          <Item onClick={() => {
            removeEdgeAndUpdateGraph(edgeSelected, topic, graph, setGraph)
            setShowContextMenu(false);
            hideAll()
          }}>Delete arrow</Item>
          : null}

        {showChatAboutContextButton && lastHoveredNode !== 1 ?
          <Item onClick={() => {
            removeNodeAndUpdateGraph(lastSelectedNode.id, topic, graph, setGraph)
            setShowContextMenu(false);
            hideAll()
          }}>Delete</Item>
          : null}

        {!showChatAboutContextButton ?
          <Item onClick={() => {
            handleAddNewNode(graph, networkRef, topic, setGraph);
            setShowContextMenu(false);
            hideAll()
          }}>Create a new node</Item>
          : null}
      </Menu>

    </div>
  );
};

export default GameGraph;

function scheduleCardAnswerNotification(answerNotificationTimerRef, selectedCardId, notice, networkRef, pulseNodeTimerRef, timeout = 30000) {
  if (!answerNotificationTimerRef.current) {
    answerNotificationTimerRef.current = setTimeout(() => {
      notifyGetAnswer(selectedCardId, notice, networkRef, pulseNodeTimerRef);
      answerNotificationTimerRef.current = undefined;
    }, timeout);
  }
}

function removeCardAndUpdateIDs(cards, cardIdToRemove) {
  const updatedCards = cards.filter((card) => card.id !== cardIdToRemove);

  return updatedCards.map((card, index) => ({
    ...card,
    id: index,
  }));
}