import { IoPlay } from "react-icons/io5";
import { IoIosPause } from "react-icons/io";
import { BiHome, BiUser } from "react-icons/bi";
import { LuLoader } from "react-icons/lu";
import { AiOutlineClose } from "react-icons/ai";
import { RiAddLargeFill } from "react-icons/ri";

import GamePointsDisplay from "./GamePointsDisplay";

const Navbar = ({
    isHome = false,
    handlePlayClick,
    playing,
    isFetching = false,
    points,
    setShowLoginModal,
    handleRemoveTopic,
    levelCardGame,
    handleAddNewNode
}) => {
    return <div className='navbar'>
        {!isHome ?
            <button title="Go home" onClick={() => window.location.href = '/'} className='navbar-item icon'>
                {<BiHome />}
            </button>
            :
            null}

        {setShowLoginModal ?
            <button title="Your account" onClick={setShowLoginModal} className='navbar-item icon'>
                {<BiUser />}
            </button>
            : null}

        {handlePlayClick ?
            <button title="Toggle physics" onClick={handlePlayClick} className='navbar-item icon'>
                {!playing ? <IoPlay /> : <IoIosPause />}
            </button>
            :
            null}

        {handleAddNewNode ?
            <button
                onClick={handleAddNewNode}
                className="navbar-item icon"
                title="Create a new node"
            >
                <RiAddLargeFill />
            </button>
            :
            null}

        {handleRemoveTopic ?
            <button
                onClick={handleRemoveTopic}
                className="navbar-item icon"
                title="Remove Topic"
            >
                <AiOutlineClose />
            </button>
            :
            null}



        {levelCardGame ?
            <div title="Your level" className="navbar-item disabled">
                <small>Lvl. {levelCardGame}</small>
            </div>
            :
            null}

        {points ?
            <div title="Earn points by solving challenges" className="navbar-item disabled">
                <GamePointsDisplay totalPoints={points} />
            </div>
            :
            null}

        {isFetching ? <button onClick={handlePlayClick} className='navbar-item icon blink_me rotating disabled'>
            <LuLoader />
        </button> : null}
    </div>;
}

export default Navbar