export default function pulseNode(nodeId = 1, networkRef, pulseNodeTimerRef, rgb = [255, 255, 255]) {
  if (!networkRef.current) {
    return;
  }

  if (!pulseNodeTimerRef.current) {
    pulseNodeTimerRef.current = { nodeId: null, stopAnimation: null };
  }

  if (pulseNodeTimerRef.current.stopAnimation) {
    pulseNodeTimerRef.current.stopAnimation()
  }

  let pulseRadius = 10;
  let maxRadius = 50;
  let increment = 0.2;
  let alpha = 0.5;

  const drawPulse = (ctx) => {
    const nodePosition = networkRef.current.getPositions([nodeId])[nodeId];
    if (!nodePosition) {
      return;
    }

    const group = networkRef.current.body.data.nodes.get(nodeId).group;
    if (group === "loading") {
      return;
    }

    ctx.beginPath();
    ctx.arc(nodePosition.x, nodePosition.y, pulseRadius, 0, 2 * Math.PI);
    ctx.fillStyle = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`;
    ctx.fill();

    pulseRadius += increment;
    alpha -= 0.005;
    if (pulseRadius > maxRadius || alpha <= 0) {
      pulseRadius = 1;
      alpha = 0.5;
    }
  };

  const afterDrawingListener = (ctx) => drawPulse(ctx);

  networkRef.current.on("afterDrawing", afterDrawingListener);

  let animationFrameId;
  const animate = () => {
    networkRef.current.redraw();
    animationFrameId = requestAnimationFrame(animate);
  };

  animate();

  const stopAnimation = () => {
    cancelAnimationFrame(animationFrameId);
    networkRef.current.off("afterDrawing", afterDrawingListener);
  };

  pulseNodeTimerRef.current = { nodeId, stopAnimation };
}
