import React from "react";
import { IoCloseCircle } from "react-icons/io5";
import constants from "../helpers/constants";
import pulseNode from "../helpers/pulseNode";
import { generateRandomKey } from "../helpers/generateRandomKey";
import getNodeByLabel from "../helpers/getNodeByLabel";
import focusOnNode from "../helpers/focusOnNode";

async function getCardAnswer(cardId) {
  const response = await fetch(
    `${constants.fetchCardAnswer}?cardId=${cardId}`,
    { credentials: "include" });

  return await response.json();
}

export function notifyGetAnswer(selectedCardId, noticeService, networkRef, pulseNodeTimerRef) {
  noticeService.open({
    key: "notifyGetAnswer",
    duration:999,
    content: (
      <div>
        <div className="d-flex justify-content-between px-2 mb-2">
          <small className="tag">Challenge</small>
          <strong>Need help?</strong>
        </div>
        <div>I can reveal the answer to you.</div>
        <button
          onClick={async () => {
            noticeService.close("notifyGetAnswer");
            const answer = await getCardAnswer(selectedCardId)
            const node = getNodeByLabel(answer.answer, networkRef)
            pulseNode(node.id, networkRef, pulseNodeTimerRef)
            focusOnNode(node.id, networkRef)
          }}
          type="submit"
          className="navbar-item ms-0 mt-2"
        >
          View Answer
        </button>
      </div>
    ),
    closable: true,
    showProgress: false,
    closeIcon: <IoCloseCircle />,
  });
}
