import React, { useEffect, useRef, useState } from 'react';
import '../style/Chat.css';
import fetchNodeChat, { getMessagesByTopic } from '../helpers/fetchNodeChat'
import MarkdownRenderer from './MarkdownRenderer';
import { notifyAndInviteToSubscribe } from './notifyAndInviteToSubscribe';

const getReinforcementLabelClass = (reinforcementValue) => {
  return {
    "-1": "incorrect",
    "0": "",
    "+1": "correct",
  }[reinforcementValue] || ""
}

const softenModalBackdrop = (timeout = 2000) => {
  const backdropElement = document.querySelector('.modal-backdrop');

  if (backdropElement) {
    backdropElement.classList.add('soft');
    backdropElement.classList.remove('hard');
    setTimeout(() => {
      backdropElement.classList.remove('soft');
      backdropElement.classList.add('hard');
    }, timeout)
  } else {
    console.error('Parent element with class ".modal-backdrop" not found.');
  }
}

const Chat = ({ topic, subject, notice, setShowLoginModal, setLevelUpCardGame }) => {
  const initialized = useRef(false);
  const messagesEndRef = useRef(null);
  const [messages, setMessages] = useState(() => getMessagesByTopic(subject));
  const [inputMessage, setInputMessage] = useState(`Can you help me to understand ${subject}?`);
  const [isLoading, setIsLoading] = useState(false);
  const [reinforcementLabelClass, setReinforcementLabelClass] = useState("");

  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    setMessages((prev) => [
      { role: 'bot', content: "I'm here to support and guide your learning journey. Keep in mind that I often make mistakes.", timestamp: new Date() },
      ...prev
    ]);

    setInputMessage('');

    if (!messages.length) {
      handleSendMessage();
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;

    const userMessage = {
      role: 'user',
      content: inputMessage.trim(),
      timestamp: new Date(),
    };

    setMessages((prev) => [...prev, userMessage]);
    setInputMessage('');
    setIsLoading(true);

    const { message, reinforcementLabel } = await fetchNodeChat(userMessage, messages.at(-1), subject, topic);
    setMessages((prev) => [...prev, message]);

    try {
      const reinforcementLabelClass = getReinforcementLabelClass(reinforcementLabel);
      setReinforcementLabelClass(reinforcementLabelClass)
      setTimeout(() => {
        setReinforcementLabelClass("")
      }, 1000)

      if (reinforcementLabelClass === "correct") {
        softenModalBackdrop();
        setLevelUpCardGame(true)
        setTimeout(() => {
          setLevelUpCardGame(false)
        }, 2000)
      }

    } catch (error) {
      console.error(error)
    }

    setIsLoading(false);

    if (message.status === 429) {
      notifyAndInviteToSubscribe(notice, { message: message.content }, setShowLoginModal)
    }

  };

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='chat-parent'>
      <div className={`chat-container ${reinforcementLabelClass}`}>
        <div className="messages-container">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`message ${message.role === 'user' ? 'message-user' : 'message-bot'}`}
            >
              <MarkdownRenderer>
                {message.content}
              </MarkdownRenderer>
              <small className="timestamp">{new Date(message.timestamp).toLocaleTimeString()}</small>
            </div>
          ))}
          {isLoading && <div className="loading">Typing...</div>}
          <div ref={messagesEndRef} />
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder="Type your message..."
            value={inputMessage}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSendMessage();
            }}
          />
        </div>
      </div>
      <button className="navbar-item mt-2" onClick={handleSendMessage}>Send</button>
    </div>
  );
};

export default Chat;
